.sidebar-footer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: var(--border-default);
    background-color: var(--bg-sidebar);
}

.sidebar-footer .sidebar-button{
    padding: 10px 12px;
    font-size: 14px;
    gap: 6px;
    margin-bottom: 2px;
}
