  
.compacted-document {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}
   
.compacted-document-icon {
    height: 22px;
    width: 22px;
    border-radius: 2px;
}