.section{
    background-color: var(--bg-internalchat);
    border: 1px solid var(--border-default);
    border-radius: 10px;
    padding: 28px 40px 16px 40px;
    display: grid;
    gap: 8px;
    max-width: 100%;
    min-width: 220px;
    overflow: hidden;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.title-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: var(--text-primary);
}
.section h2{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: left;
    color: var(--text-primary);
}

label{
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
}

.field{
    padding: 24px 0;
    border-top: 1px solid var(--border-table);
    margin-bottom: 0 !important;
    width: 100%;
}

.field select {
    border: 1px solid var(--textfield-border);
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 48px;
    padding: 0 4px 0 12px;
    width: 250px;
    background-color: var(--bg-internalchat);
    color: var(--text-primary);
    font-size: 14px;
}

.personal-info-content input{
    width: 100%;
    margin-top: 12px;
}

@media (max-width: 768px) {
    .section{
        padding: 18px 20px 4px 20px;
    }
    .section h2{
        font-size: 18px;
    }
  }