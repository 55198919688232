.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-modal-outside);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-cargaperiodica {
    background-color: var(--bg-modal);
    border-radius: 16px;
    width: 50%;
    padding: 0;
    border: 1px solid var(--border-default);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 28px;
    border-bottom: 1px solid var(--border-default);
    border-radius: 16px 16px 0 0;
}

.modal-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-primary);
}

.modal-header .close-btn {
    background: none;
    border: none;
    color: var(--icon-reaction-text);
    font-size: 18px;
    cursor: pointer;
    width: 36px;
    height: 36px;
}

.modal-body {
    padding: 28px 28px 35px 28px;
    margin: 0;
    display: grid;
    gap: 24px;
}

.modal-section {
    display: flex;
    flex-direction: column;
}

.description-title {
    font-size: 14px;
    color: var(--text-secondary);
    line-height: 20px;
    font-weight: 300;
}

.search-container {
    position: relative;
}

.search-icon {
    position: absolute;
    top: 48%;
    left: 12px;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
}

textarea {
    width: 100%;
    padding: 12px 14px;
    border-radius: 6px;
    border: 1px solid var(--textfield-border);
    background-color: var(--textfield-bg);
    color: var(--text-primary);
    font-size: 14px !important;
    margin-top: 12px;
    resize: vertical;
    height: 44px;
}

textarea::placeholder {
    color: var(--text-placeholder);
}


.modal-footer-left-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px 28px;
    border-top: 1px solid var(--border-default);
    margin-top: 0;
}

.footer-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footer-left span {
    color: var(--text-primary);
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 24px;
}

.view-source {
    color: var(--gray-400);
    font-size: 14px;
    text-decoration: underline;
}

.view-source:hover {
    text-decoration: underline;
}

.primary-btn {
    background-color: var(--btn-primary-bg);
    color: var(--btn-primary-text);
    border-radius: 10px;
    padding: 12px 22px;
    font-size: 16px;
    line-height: 22px;
}
.primary-btn:hover {
    background-color: var(--btn-primary-bg-hover);
    color: var(--btn-primary-text-hover);
}

.cancel-btn {
    background-color: transparent;
    color: var(--btn-secondary-text);
    border: 1px solid var(--btn-secondary-border);
    border-radius: 10px;
    padding: 12px 22px;
    font-size: 16px;
    line-height: 22px;
}

.modal-footer button.cancel-btn:hover {
    background-color: var(--gray-900);
}

.help-container {
    color: white; 
}
.cancel-btn:hover {
    background-color: var(--btn-secondary-bg-hover);
    border: 1px solid var(--btn-secondary-border-hover);
    color: var(--btn-secondary-text-hover);
}

.css-hlgwow, .css-1dyz3mf {
    top: -6px;
}
.css-1wy0on6{
    height: 46px;
}
.css-lydywf-multiValue{
    color: var(--options-text-hover);
    background-color: var(--options-bg-hover);
}

@media (max-width: 768px) {
    .modal-cargaperiodica {
        width: 100%;
        max-width: unset;
        border-radius: 12px;
        margin: 0;
    }
    .modal-body {
        padding: 20px 16px;
    }
    .modal-header, .modal-footer {
        padding: 16px;
    }
    .modal-footer-left-info{
        display: block;
    }
    .footer-right{
        display: flex;
        margin-top: 12px;
    }
    .footer-right .cancel-btn{
        margin: 0;
    }
}