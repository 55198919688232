/* Contenedor para centrar el SVG y el texto */
.loading-container {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    padding: 20px;
    gap: 20px;
  }
  
  /* Estilos para el texto de carga */
  .loading-text {
    font-size: 16px;
    color: var(--text-secondary);
    font-weight: 500;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Animación del texto */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  