.nolike-button{
    display: flex;
    align-items: center;
    position: relative;
    padding: 6px;
    border-radius: 4px;
    color: var(--icon-reaction-text);
    width: 32px;
    height: 32px;
}
.nolike-button:hover{
    color: var(--icon-reaction-text-hover);
    background-color: var(--icon-reaction-bg-hover);
}

.nolike-button-icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    transition: color 0.3s ease;
    transition: fill 1s ease; /* Transición suave para el cambio de color */

}

.modal-feedback {
    background-color: var(--bg-modal);
    max-width: 700px;
    border: 1px solid var(--border-default);
    border-radius: 16px; 
    display: flex;
    flex-direction: column; 
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.modal-body {
    flex: 1; 
    overflow-y: auto; 
    padding: 20px; 
    box-sizing: border-box;
}

.feedback-options {
    display: flex;
    flex-wrap: wrap; 
    gap: 12px;
    margin-top: 14px;
}

.feedback-option {
    display: inline-block;
    border: 1px solid var(--options-border);
    padding: 6px 14px;
    border-radius: 6px;
    cursor: pointer;
    background-color: transparent;
    color: var(--options-text);
    white-space: nowrap;
}

.feedback-option:hover {
    background-color: var(--options-bg-hover);
    color: var(--options-text-hover);
}

.feedback-option.selected {
    border: 1px solid var(--options-border-selected);
    background-color: var(--options-bg-selected);
    color: var(--options-text-selected);
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid var(--border-default);
}

.nolike-button.disliked .nolike-button-icon {
    /* Cambia el color o estilo que desees cuando el usuario no le guste la respuesta */
    fill: var(--nolike-disliked-color); /* Por ejemplo, un color rojo para representar desagrado */
  }

@media (max-width: 768px) {
    .modal-feedback {
        width: 100%;
        border-radius: 12px;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .modal-body {
        max-height: calc(100% - 120px);
        overflow-y: auto;
    }
    .modal-feedback textarea{
        height: 90px;
    }
}