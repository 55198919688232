.modal-filters {
    background-color: white;
    border-radius: 16px;
    color: white;
}


.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-field-selection {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    gap: 12px;
    margin: 16px 20px;
    border-radius: 8px;
}

.modal-field-selection select {
    width: 86%;
    padding: 14px 12px;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    resize: vertical;
}

.create-btn {
    background-color: var(--pomelo-500);
    color: var(--text);
    border-radius: 8px;
    padding: 13px 22px;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
}

.input-group{
    margin-bottom: 24px;
}

.input-group label{
    display: block;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    margin-bottom: 6px;
    padding-left: 4px;
}

.input-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.input-with-icon input {
    width: 95%;
    padding: 14px;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
}


.icon-delete {
    width: 40px;
    padding: 8px;
    border-radius: 30px;
}

.icon-delete:hover {
}
.modal-body-filters{
    padding: 20px 20px 12px 32px;
    justify-content: space-between;
    gap: 24px;
}
