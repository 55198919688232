/* Footer.css */

.footer {
    color: var(--text-secondary); 
    bottom: 0;
    padding: 1.8rem 0;
  }
  
  .container.footer-content.flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }

  .copyright{
    display: flex;
  }

  .icon_copyright, .icon_facebook, .icon_twitter, .icon_linkedin{
    margin-right: 6px;
    margin-top: 2px;
  }

  .footer-p{
  }

  .footer-logo img {
    max-width: 100px;
  }
  
  .footer-nav {
    list-style: none;
    padding: 0;
    display: flex;
  }
  
  .footer-link{
    text-decoration: none;
    font-weight: 500;
    font-size: 1.6rem;
  }

  .footer-item.margins{
    margin: 0 10px;
  }

  .footer-link:hover {
    text-decoration: none; 
    color: var(--red-color);

  }
  
  .footer-social {
    display: flex;
    justify-content: right;
    gap: 10px;
  }


@media (min-width: 768px) and (max-width: 1024px){
    .container.footer-content.flex{
      padding: 0 10px;
    }
    .footer-p, .footer-link, .footer-item.margins{
        font-size: 12px;
        margin-top: 8px;
    }
    .footer-item.margins{
      margin-top: 4px;
    }
    .footer-p.copy{
      margin-top: 5px;
    }
    .icon_copyright{
      margin-top: 4px;
    }
}

@media (max-width: 768px){
    .container.footer-content.flex{
        display: block;
        padding: 0 14px;
    }
    .footer-p, .footer-link, .footer-item.margins{
      font-size: 12px;
    }
    .footer-nav{
      display: block;
    }
    .footer-item.margins{
      margin-left: 0;
    }
    .footer-p.copy{
      margin-top: 4px;
    }
    .footer-social{
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 16px;
    }
}