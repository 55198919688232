.like-button{
    display: flex;
    align-items: center;
    position: relative;
    padding: 6px;
    border-radius: 4px;
    color: var(--icon-reaction-text);
    width: 32px;
    height: 32px;
}
.like-button:hover{
    color: var(--icon-reaction-text-hover);
    background-color: var(--icon-reaction-bg-hover);
}

.like-button-icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    transition: color 0.3s ease;
    transition: fill 1s ease; /* Transición suave para el cambio de color */

}

.like-button-icon.liked {
    fill: var(--like-liked-color); /* Color cuando se hace "like" (rojo tomate, por ejemplo) */
  }