.modal-changepassword{
    max-width: 520px !important;
    background-color: var(--bg-modal);
    border-radius: 16px;
    border: 1px solid var(--border-default);
}
.modal-changepassword label{
    font-size: 14px;
    color: var(--text-secondary);
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 4px;
}
.modal-changepassword input{
    width: 100%;
    padding: 12px 14px;
    border-radius: 6px;
    border: 1px solid var(--textfield-border);
    background-color: var(--textfield-bg);
    color: var(--text-primary);
    font-size: 14px !important;
    resize: vertical;
    height: 44px;
    margin: 0;
}