.chat-input-form {
  display: flex;
  flex-direction: column;
  background-color: #3b3b3e;
  border: 1px solid #525255;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.input-row {
  display: flex;
}

.intention-select {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 14px;
  margin-right: 10px;
  outline: none;
  flex-shrink: 0;
}

.chat-input {
  flex-grow: 1;
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 14px;
  outline: none;
}

.doc-id-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Espacio entre filas de IDs */
}

.doc-id-row input {
  background-color: transparent;
  color: #fff;
  border: 1px solid #525255;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  flex-grow: 1; /* Para que el input ocupe el espacio disponible */
}

.doc-id-row button {
  background-color: var(--red-color); /* Color para el botón de eliminar */
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.doc-id-row button:hover {
  background-color: var(--dark-red-color); /* Color al pasar el mouse */
}

.metadata-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Espacio entre la fila de metadatas y el botón de enviar */
}

.metadata-row label {
  color: #fff;
  margin-right: 10px; /* Espacio entre la etiqueta y el input */
}

.metadata-row input {
  background-color: transparent;
  color: #fff;
  border: 1px solid #525255;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  flex-grow: 1; /* Para que el input ocupe el espacio disponible */
}

.add-doc-id-btn {
  background-color: var(--blue-color);
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px; /* Espacio entre el botón y el siguiente elemento */
}

.add-doc-id-btn:hover {
  background-color: var(--dark-blue-color);
}

input:-internal-autofill-selected{
  background-color: var(--gray-900);
}