.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh; 
  background-color: var(--bg-surface);
  padding: 20px; 
}

.welcome-logo {
  height: 100px;
  width: auto;
  margin-bottom: 20px; 
}

.welcome-title {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  color: var(--text-primary);
  margin-bottom: 16px;
  max-width: 600px; 
}

.welcome-description {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: var(--text-secondary);
  margin-bottom: 32px;
  max-width: 600px; 
}

.custom-button {
  font-size: 16px;
  padding: 14px 24px;
  border-radius: 8px;
  border: 1px solid var(--btn-primary-border);
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-text);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-button:hover {
  background-color: var(--btn-primary-bg-hover);
  border: 1px solid var(--btn-primary-border);
  color: var(--btn-primary-text-hover);
}