.document-element {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid var(--bg-default);
  background-color: var(--bg-default);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  gap: 5px;
  height: 100%;
}

.element-selected {
  background-color: var(--bg-default-hover);
  border: 1px solid var(--bg-default-hover);
}

.document-element:hover {
  background-color: var(--bg-default-hover);
}

.document-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-source {
  display: flex;
  align-items: center;
}
.icon-source p{
  color: var(--gray-800);
  font-size: 12px;
  font-weight: 500;
}

.document-icon {
  width: auto;
  height: 20px;
  margin-right: 4px;
  border-radius: 2px;
}

.document-source {
  font-size: 12px;
  color: var(--text-secondary);
}

.document-index {
  color: var(--text-secondary);
  font-size: 12px;
  font-weight: 500;
}

.document-title {
font-size: 0.9em;
color: var(--text-primary);
font-weight: 600;
line-height: 20px;
}

.document-score {
  margin-left: 20px;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: var(--gray-200);
}