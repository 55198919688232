.search-bar{
    border: 1px solid var(--textfield-border);
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 48px;
    padding: 0 4px 0 12px;
}
.search-expanded{
    width: 330px;
}
.search-input {
    flex: 1;
    border: none;
    outline: none;
    background: none;
    padding: 0 4px;
    font-size: 14px;
    color: var(--text-primary);
}

.icon-left {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--textfield-icon);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.icon-right {
    margin-left: 8px;
    border-radius: 6px;
    padding: 8px;
    color: var(--icon-reaction-text);
}

.icon-right:hover {
    color: var(--icon-reaction-text-hover);
    background-color: var(--icon-reaction-bg-hover);
}

.icon i {
    font-size: 20px;
}

.search-input::placeholder {
    color: var(--text-placeholder);
}