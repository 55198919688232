.document-group{
  display: flex;
  flex-direction: column;
  padding: 10px 14px 5px 14px;
  border-radius: 6px;
  background-color: var(--gray-100);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.document-group-elements {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 6px;
  margin-bottom: 4px;
}

.document-group:hover {
  background-color: var(--gray-200);
}

.document-group-text {
  color: var(--white-color);
  font-size: 12px;
  font-weight: 500;
}