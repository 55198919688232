/* ErrorMessage.css */
.error-message-box {
  padding: 20px;
  border-radius: 5px;
  background-color: var(--gray-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  text-align: left; /* Align text to the right */
}

.error-message-box p {
  margin: 0;
  font-size: 16px;
  color: black;
}
