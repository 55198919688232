
/* Si quieres que el botón no ocupe toda la pantalla en móviles, puedes agregar esto */
@media (max-width: 768px) {
    .sidebar-conversation-button-container {
        padding: 5px;
    }
}

.sidebar-button-wrapper {
    position: relative;
}

.sidebar-button-wrapper .sidebar-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    background: none;
    border-radius: 8px;
    color: var(--sidebar-option-text);
    width: 100%;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.3s ease;
}

.sidebar-button-wrapper .sidebar-button-content {
    display: flex;
    align-items: center;
    width: 92%;
}

.sidebar-button .icon {
    font-size: 22px;
}

.sidebar-button:hover, .sidebar-button.active {
    background-color: var(--sidebar-option-bg-hover);
}

.sidebar-button span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

.sidebar-button-wrapper .sidebar-button .menu-icon {
    display: none;
    font-size: 16px;
    cursor: pointer;
}

.sidebar-button-wrapper:hover .sidebar-button .menu-icon {
    display: block;
    color: var(--sidebar-option-text-hover);
}

.sidebar-button-wrapper .options-menu {
    position: absolute;
    padding: 8px;
    top: 100%;
    left: 80px;
    background-color: var(--dropdown-bg);
    border: 1px solid var(--dropdown-border);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
    z-index: 1000;
    width: 190px;
    border-radius: 12px;
    margin-top: 5px;
    font-size: 14px;
}

.sidebar-button-wrapper:hover .sidebar-button {
    opacity: 1;
    visibility: visible;
}

.options-menu .option .icon-option{
    color: var(--sidebar-option-text);
}
.options-menu .option .icon-option:hover{
    color: var(--dropdown-option-text-hover);
}