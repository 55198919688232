.messages-placeholder {
    text-align: center;
    padding: 150px;
  }
  
  .messages-placeholder-header {
    margin-bottom: 20px;
  }
  
  .messages-placeholder-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .messages-placeholder-logo img {
    width: 150px;
    height: auto;
    border-radius: 50%;
  }
  
  .messages-placeholder-logo h1 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .messages-placeholder-main-content {
    margin-top: 20px;
  }
  
  .messages-placeholder-main-question {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .messages-placeholder-collection-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .messages-placeholder-questions-grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  
  .messages-placeholder-toggle-form {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    border: 1px var(--border-default);
    transition: transform 0.2s;
  }
  
  .messages-placeholder-toggle-form button {
    cursor: pointer;
    background-color: white;
    padding: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .messages-placeholder-toggle-form:hover {
    transform: scale(1.05);
  }
  
  .messages-placeholder-question-card:hover {
      transform: scale(1.05);
  }
  .messages-placeholder-question-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .messages-placeholder-question-card:hover {
    transform: scale(1.05);
  }
  