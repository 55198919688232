.sources-area {
  height: 100%;
  flex-grow: 1;
  background-color: var(--bg-surface);
  margin: 0;
  padding: 5% 6%;
}

#main-sources-table {
  background-color: var(--bg-internalchat);
  border: 1px solid var(--border-default);
  border-radius: 10px;
  padding: 28px;
  display: grid;
  gap: 20px;
  max-width: 100%;
  min-width: 220px;
  overflow: hidden;
  box-sizing: border-box;
}

.header-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.info-table {
  flex: 1 1 300px;
}

.filters-cta-table {
  display: flex;
  gap: 12px;
  flex: 1 1 1;
  justify-content: flex-start;
}

tr td{
  font-size: 14px;
}
.expanded-parent tr td{
  font-size: 14px;
}

.expanded-row td{
  padding: 28px 22px;
}

.title-table {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-primary);
  line-height: 32px;
}

.total-number-table {
  font-size: 14px;
  font-weight: 300;
  color: var(--text-secondary);
  line-height: 18px;
}
.table{
  border-radius: 8px;
  border: 1px solid var(--border-table);
}
table {
  width: 100%;
  border-collapse: collapse;
}

thead tr {
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 400;
}

tbody tr {
  border-top: 1px solid var(--border-table);
  color: var(--text-secondary);
}

.expanded-content thead tr {
  border-top: none;
}

td, th {
  padding: 12px;
  text-align: left;
  color: var(--text-secondary);
}

.table thead tr th {
  text-align: left;
  padding: 10px 12px;
  white-space: nowrap;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 13px;
  color: var(--text-primary);
}

.filter-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.toggle-row-button {
  color: var(--text-secondary);
}

tbody tr.expanded-row, tbody tr.expanded-row:hover {
  background-color: var(--bg-expanded-row);
}

#main-sources-data-table {
  width: 100%;
  overflow-x: auto;
}

tbody tr:hover{
  background-color: var(--bg-tr-hover);
}

@media (max-width: 668px) {
  .filters-cta-table {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .sources-area {
    padding: 3% 4% 0 4%;
  }
  .search-bar{
    width: 278px !important;
  }
  #main-sources-table{
    padding: 18px 20px;
    gap: 12px;
  }
  #main-sources-table .header-table{
    margin-bottom: 6px;
  }
  #main-sources-data-table {
    padding-right: 0; 
    overflow-x: auto;
  }
}