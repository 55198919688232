.chat-input-container {
  width: 100%;
  padding: 5px 10%;
  background-color: var(--bg-surface);
}

.chat-input-form {
  display: flex;
  flex-direction: column;
  background-color: var(--search-bg);
  border: 1px solid var(--search-border);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.input-row {
  display: flex;
}

.intention-select {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 14px;
  margin-right: 10px;
  outline: none;
  flex-shrink: 0;
}

.chat-input {
  flex-grow: 1;
  background-color: transparent;
  color: var(--text-primary);
  border: none;
  padding: 10px;
  font-size: 16px;
  outline: none;
}

.doc-id-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Espacio entre filas de IDs */
}

.doc-id-row input {
  background-color: transparent;
  color: #fff;
  border: 1px solid #525255;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  flex-grow: 1; /* Para que el input ocupe el espacio disponible */
}

.doc-id-row button {
  background-color: var(--red-color); /* Color para el botón de eliminar */
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.doc-id-row button:hover {
  background-color: var(--dark-red-color); /* Color al pasar el mouse */
}

.metadata-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Espacio entre la fila de metadatas y el botón de enviar */
}

.metadata-row label {
  color: #fff;
  margin-right: 10px; /* Espacio entre la etiqueta y el input */
}

.metadata-row input {
  background-color: transparent;
  color: #fff;
  border: 1px solid #525255;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  flex-grow: 1; /* Para que el input ocupe el espacio disponible */
}

.add-doc-id-btn {
  background-color: var(--blue-color);
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.add-doc-id-btn:hover {
  background-color: var(--dark-blue-color);
}

.chat-send-btn {
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-text);
  border: none;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 48px;
  height: 48px;
}

.chat-send-btn svg {
  width: 20px;
  height: 20px;
}

.chat-send-btn:hover {
  background-color: var(--btn-primary-bg-hover);
}

.chat-tools-btn {
  border: 1px solid var(--bg-default-hover);
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
}

.chat-tools-btn:hover {
  background-color: var(--bg-default-hover);
}

.active {
  background-color: var(--answer-bg);
}

.external-search-form {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 10px;
  margin: 5px;
  padding: 5px;
  background-color: var(--search-bg)
}

.external-search-send-btn {
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-text);
  border: none;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 48px;
  width: 48px;
}

.external-search-send-btn:disabled {
  background-color: var(--btn-disabled-bg, #ccc); /* Color gris por defecto si no se define la variable */
  color: var(--btn-disabled-text, #666);
  cursor: not-allowed;
  opacity: 0.6; /* Hace que se vea deshabilitado */
}

.search-container {
  width: 300px;
}

.external-search-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Espacio entre píldoras */
  margin-top: 10px;
  margin-bottom: 10px;
}

.external-search-chips .chip {
  display: inline-flex;
  align-items: center;
  background-color: var(--bg-default);
  color: var(--text-primary);
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 20px;
}

.external-search-chips .chip .chip-remove {
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
  color: #004d40;
  background: none;
  border: none;
  font-size: 16px;
}

.chip .chip-remove:hover {
  color: #d32f2f; /* Rojo al pasar el cursor */
}

.rag-button-container {
  position: relative;
  display: inline-block;
  padding-top: 5px;
}

.rag-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.rag-button:hover {
  background-color: var(--btn-primary-bg-hover);
  transform: translateX(5px);
}

.rag-button.active {
  background-color: var(--btn-primary-bg);
  color: white;
}

.tooltip {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--btn-primary-bg-hover);
  color: white;
  font-size: 15px;
  padding: 4px 8px;
  border-radius: 20px;
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


@media (max-width: 768px) {
  .chat-input-container {
      padding: 4% 6%;
  }
  .chat-input-form input {
    height: 40px;
  }
}