.preview-area {
  flex: 1;
  min-width: 338px;
  background-color: var(--bg-surface);
  border-left: 1px solid var(--border-default);
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
}

.preview-header {
  border-bottom: 1px solid var(--border-default);
  padding: 14px 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--bg-surface);
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.source-count {
  color: var(--text-primary);
  font-size: 18px;
  height: 26px;
  font-weight: 600;
  word-wrap: break-word;
}

.question-title {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
}

.close-btn {
  background: none;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  color: var(--icon-action-text);
  width: 36px;
}

.close-btn:hover {
  color: var(--icon-action-text);
  background-color: var(--icon-action-bg-hover);
  border-radius: 30px;
}

.preview-actions {
  padding: 14px 20px;
  background-color: var(--bg-surface);
  border-bottom: 1px solid var(--border-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 1;
}

.preview-actions-right {
  display: flex;
  gap: 8px;
}

.select-all, .create-query-btn {
  padding: 10px 12px 11px 12px;
  border: 1px solid var(--icon-action-border);
  border-radius: 8px;
  font-size: 14px;
}

.select-all:hover, .create-query-btn:hover {
  background-color: var(--icon-action-bg-hover);
}

.actions-sources {
  display: flex;
  gap: 8px;
}

.actions-sources .action-hook-button {
  height: 40px;
  width: 40px;
  border: 1px solid var(--icon-action-border);
  color: var(--icon-action-text);
}

.document-list {
  padding: 20px 20px 0 20px;
  overflow-y: auto;
  flex-grow: 1;
  scrollbar-width: thin;
  scrollbar-color: var(--gray-600) var(--dark-gray-color);
}

.document-list::-webkit-scrollbar {
  width: 12px;
}

.document-list::-webkit-scrollbar-track {
  background: var(--dark-gray-color);
}

.document-list::-webkit-scrollbar-thumb {
  background-color: var(--gray-600); 
  border-radius: 6px; 
  border: 3px solid var(--dark-gray-color); 
  margin-left: 10px;
}

.preview-area .chat-input-container {
  padding: 20px;
  border-bottom: 1px solid var(--border-default);
}
.preview-area .chat-send-btn svg {
  width: 16px;
  height: 16px;
}

.preview-area.modal {
  position: fixed;
  top: 0;
  right: 0; 
  height: 100%;
  width: 92%; 
  max-width: 100%;
  z-index: 1000;
  transform: translateX(0); 
  transition: transform 0.3s ease-in-out;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
}

.preview-area .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.document-list {
  overflow-y: auto;
  flex-grow: 1;
  scrollbar-width: thin;
  scrollbar-color: var(--gray-600) var(--dark-gray-color);
}

.document-list::-webkit-scrollbar {
  width: 12px;
}

.document-list::-webkit-scrollbar-track {
  background: var(--dark-gray-color);
}

.document-list::-webkit-scrollbar-thumb {
  background-color: var(--gray-600); 
  border-radius: 6px; 
  border: 3px solid var(--dark-gray-color); 
  margin-left: 10px;
}

.actions-sources {
  display: flex;
  gap: 8px;
}

.dropdown-container {
  position: relative;
}

.three-dots-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--icon-action-text);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.three-dots-btn:hover {
  color: var(--icon-action-hover);
}

.dropdown-menu {
  position: absolute;
  right: 0;
  width: 218px;
  background-color: var(--dropdown-bg);
  border: 1px solid var(--border-default);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  color: var(--dropdown-option-text);
  padding: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
  border-radius: 6px;
}
.dropdown-menu .dropdown-item:hover {
  background-color: var(--dropdown-option-bg-hover);
  color: var(--dropdown-option-text-hover);
}

.dropdown-menu button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
}

.select-all:hover, .create-query-btn:hover {
  background-color: var(--icon-action-bg-hover);
}


@media (max-width: 499px) {
  .preview-area.modal {
    min-width: 94%;
  }
  .preview-header, .preview-actions, .document-list {
    padding: 14px;
  }
  .preview-area .document-row {
    margin: 0 0 14px 0;
  }
}

@media (min-width: 500px) and (max-width: 912px) {
  .preview-area.modal {
    min-width: 500px;
  }
}

@media (max-width: 1244px) and (min-width: 768px) {
  .dropdown-container {
    display: block; /* Asegura que el contenedor sea visible */
  }
  .three-dots-btn {
    display: flex; /* Muestra el botón de los tres puntos */
    align-items: center;
    justify-content: center;
  }
}