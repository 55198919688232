.new-query-button-FullScreen{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: var(--icon-action-text);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--icon-action-border);
}
.new-query-button-FullScreen:hover{
    background-color: var(--icon-action-bg-hover);
    fill: var(--icon-action-text-hover);
}

.new-query-button-FullScreen.loading {
    cursor: not-allowed;
    pointer-events: none; 
    opacity: 0.4;
}

.new-query-button-FullScreen .icon{
    fill: var(--icon-action-text);
}

.new-query-button-FullScreen .icon:hover{
    fill: var(--icon-action-text-hover);
}

@media (max-width: 768px) {
    .new-query-button-FullScreen{
        width: 36px;
        height: 36px;
    }
}