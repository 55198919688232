#btn-editcargaperiodica {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 8px;
    font-size: 14px;
    height: 48px;
    transition: all 0.3s ease;
}
  
#btn-editcargaperiodica:hover {
    background-color: var(--primary-dark);
}
#btn-editcargaperiodica img {
width: 24px;
height: 24px;
}
  
#btn-editcargaperiodica span {
display: inline-block;
transition: opacity 0.3s ease;
max-width: 108px;
}

#btn-editcargaperiodica.compressed {
    gap: 0;
  }
  
#btn-editcargaperiodica.compressed span {
    display: none;
}

@media (max-width: 620px) {
    #btn-editcargaperiodica {
        gap: 0; 
    }

    #btn-editcargaperiodica span {
        opacity: 0;
        visibility: hidden;
        width: 0;
        overflow: hidden;
    }
}

  