.sidebar-button-footer {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    background: none;
    border-radius: 8px;
    color: var(--sidebar-option-text);
    width: 100%;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.3s ease;
    gap: 8px;
    margin-bottom: 4px;
}

.sidebar-button-footer svg {
    font-size: 22px;
    color: var(--sidebar-menu-text);
}

.sidebar-button-footer:hover,
.sidebar-button-footer.active {
    background-color: var(--sidebar-menu-bg-hover);
    color: var(--sidebar-option-text-hover);
}

.sidebar-button-footer span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
    color: var(--sidebar-option-text);
}