.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin: 0;
    box-sizing: border-box;
}

h2 {
    font-family: 'Inter', sans-serif;
    color: var(--text-primary);
    margin-bottom: 20px;
    font-size: 28px;
    text-align: center;
}

label {
    color: var(--text-secondary);
    display: block;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

input.form-log {
    height: 44px;
    padding: 16px;
    width: 100%;
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 20px;
    border: 0.5px solid var(--textfield-border);
    font-size: 16px;
}

input.form-log:active, input.form-log:focus {
    border: 0.5px solid var(--red-color);
}

.login_cta {
    font-family: 'Inter', sans-serif;
    color: var(--btn-primary-text);
    background-color: var(--btn-primary-bg);
    border-radius: 4px;
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
}

.login_cta:hover {
    background-color: var(--btn-primary-bg-hover);
}

.col-sm-6.login {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.login_forgot {
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
}

.login-logo {
    display: block;
    margin: 0 auto;
    height: 100px;
    width: auto; 
}

.error{
    font-size: 14px;
    color: var(--red-color);
}


@media (min-width: 768px) and (max-width: 1024px) {
    .col-sm-6 {
        width: 60%;
    }
    .col-sm-6.login {
        margin-bottom: 300px;
    }
}

@media (max-width: 768px) {
    .col-sm-6.login {
        margin-bottom: 0px;
    }
}
