.custom-button {
    background-color: transparent; 
    color: #00c853; 
    border: 2px solid #00c853; 
    border-radius: 8px; 
    padding: 12px 24px; 
    font-size: 16px; 
    font-weight: bold; 
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; 
  }
  
  .custom-button:hover {
    background-color: #00c853; 
    color: white; 
  }
  