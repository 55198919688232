.label-actions{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.field-label{
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 600;
}

.field-actions{
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.field-content{
    color: var(--text-secondary);
}
.edit-btn-account, .cancel-btn-account {
    border: 1px solid var(--btn-secondary-border);
    color: var(--btn-secondary-text);
    font-size: 14px;
    border-radius: 6px;
    padding: 10px 16px;
}
.edit-btn-account:hover, .cancel-btn-account:hover{
    border: 1px solid var(--btn-secondary-border-hover);
    color: var(--btn-secondary-text-hover);
    background-color: var(--btn-secondary-bg-hover);
}

.save-btn-account{
    background-color: var(--btn-primary-bg);
    color: var(--btn-primary-text);
    font-size: 14px;
    border-radius: 6px;
    padding: 10px 16px;
}
.save-btn-account:hover{
    background-color: var(--btn-primary-bg-hover);
}

input {
    border: 1px solid var(--textfield-border);
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 48px;
    padding: 0 4px 0 12px;
    width: 500px;
}

@media (max-width: 768px) {
    .field-label {
        font-size: 14px;
    }
}