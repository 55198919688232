.actions-button-container {
    display: flex;
    margin-bottom: 0px;
    margin: 8px 0 20px 0;
}

.actions-button-container .copy-message-button {
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px;
    border-radius: 4px;
    color: var(--icon-reaction-text);
    width: 34px;
    height: 34px;
}
.actions-button-container .copy-message-button:hover {
    color: var(--icon-reaction-text-hover);
    background-color: var(--icon-reaction-bg-hover);
}