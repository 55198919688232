.table-loader-row {
    animation: fadeIn 1s ease-in-out infinite;
  }
  
  .table-loader-cell {
    height: 20px;
    position: relative;
    background: var(--options-bg-hover);
    border-radius: 6px;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
  