.chat-area {
  height: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0 10%;
  background-color: var(--bg-surface);
}

@media (max-width: 768px) {
  .chat-area {
    padding: 0 6%;
  }
}
