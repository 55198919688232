.bookmark-area {
  flex: 1;
  padding: 0 10%;
  overflow-y: auto;
  background-color: var(--bg-surface);
}
.bookmark-search{
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  margin: 5% 0 4% 0;
}
.search-bookmarkarea{
  width: 100%;
  background-color: var(--search-bg);
  border: 1px solid var(--textfield-border);
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 48px;
  padding: 0 4px 0 12px;
}

@media (max-width: 768px) {
  .bookmark-area {
      padding: 3% 4% 0 4%;
  }
  .bookmark-search{
    display: block;
  }
  .search-bookmarkarea {
    width: 100%;
  }
  .bookmark-search .sort-select{
    width: 100%;
    height: 48px;
    margin-top: 12px;
    margin-bottom: 14px;
  }
}