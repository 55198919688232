.toogle-button-FullScreen{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: var(--icon-action-text);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--icon-action-border);
}

.toogle-button-FullScreen:hover{
    background-color: var(--icon-action-bg-hover);
    fill: var(--icon-action-text-hover);
}
.toogle-button-FullScreen svg{
    width: 20px;
    height: 20px;
    color: var(--icon-action-text);
}

@media (max-width: 768px) {
    .toogle-button-FullScreen{
        width: 36px;
        height: 36px;
    }
}