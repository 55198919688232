.intention-button-container {
    position: relative;
    display: inline-block;
  }
  
  .intention-button {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: relative;
    height: 48px;
  }
  

  .intention-button:hover {
    background-color: var(--bg-default-hover);

  }
  .intention-button svg {
    height: 100%;
    width: 100%;
  }
  .button-icon {
    margin-right: 1px;
    width: 20px;
  }
  
  .button-text {
    font-size: 14px;
    color: #333; /* Color del texto del tooltip */
    background-color: transparent; /* Sin fondo */
    padding: 5px;
    border-radius: 4px;
    display: inline-block;
    white-space: nowrap;
  }
  .description {
    font-size: 10px;
    color: #888; /* Color claro para la descripción */
    margin-top: 4px;
    display: block;
  }  
 
.dropdown-menu {
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 5px;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-item.selected {
    background-color: #d1e7dd; /* Color de fondo para el ítem seleccionado */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombreado alrededor del ítem seleccionado */
  }
  
  .dropdown-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .icon-tooltip {
    display: flex;
    align-items: center;
    gap: 8px; /* Espacio entre el ícono y el tooltip */
  }