.button-tooltip {
    position: absolute; /* Posición absoluta para que no afecte el flujo del layout */
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
    opacity: 0; /* Oculto inicialmente */
    transition: opacity 0.3s ease;
    pointer-events: none; /* Elimina la interacción del cursor con el tooltip */
}

.button-tooltip.visible {
    opacity: 1; /* Muestra el tooltip */
}
