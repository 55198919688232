.bookmark-message-box {
  max-width: 100%; 
  margin-right: auto;
  margin-left: 0;
  padding: 24px 14px 20px 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  border-top: 1px solid var(--border-default);
}

.bookmark-message-box p {
  font-size: 16px;
  color: var(--text-primary);
  font-weight: 300;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.bookmark-header {
  margin-top: 16px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.bookmark-header svg {
  color: var(--text-terciary);
  height: 14px;
  width: 14px;
}

.bookmark-message-box small {
  margin: 0;
  font-size: 12px;
  color: var(--text-terciary);
  font-weight: 300;
}

.search-bar {
  padding: 40px 0 20px 0;
  text-align: center;
}

.search-input {
  width: 100%;
  padding: 16px;
  border: 1px solid var(--search-border);
  border-radius: 8px;
  background-color: var(--search-bg);
  font-size: 16px;
  color: var(--text-placeholder);
}

.bookmark-group {
  margin-bottom: 20px;
}

.bookmark-group h3 {
  margin-bottom: 12px;
  color: var(--text-secondary);
  font-weight: 500;
  font-size: 16px;
  padding: 4px 4px;
  border-radius: 6px;
}
.bookmark-group h4 {
  color: var(--text-secondary);
  margin-bottom: 8px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 14px;
}

.sort-bar {
  display: flex; 
  justify-content: flex-end;
}
.sort-select {
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid var(--textfield-border);
  color: var(--text-secondary);
  font-weight: 200;
}

.sort-select:hover {
  background-color: var(--search-bg-hover);
}