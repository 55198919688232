.sidebar {
    height: 100vh;
    min-width: 240px;
    max-width: 240px;
    background-color: var(--bg-sidebar);
    border-right: 1px solid var(--border-default);
    position: fixed; 
    top: 0; 
    left: 0; 
    z-index: 1; 
    transition: transform 0.3s ease, width 0.3s ease;
    display: flex;
    flex-direction: column;
}

.sidebar.hidden {
    transform: translateX(-100%);
}

.nueva-consulta-button {
    width: 90%; 
    max-width: 300px; 
    margin: 0 auto; 
}

.sidebar-chat-items {
    padding: 20px 18px;
    background-color:  var(--bg-sidebar);
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
}

.sidebar-chat-items h3 {
    color: var(--sidebar-option-text);
    font-size: 12px;
    font-weight: 500; 
    margin-bottom: 8px;
    width: 100%; 
    transition: color 0.2s ease;
}

.sidebar-chat-items h3:hover {
    color: var(--sidebar-option-text-hover);
}

.sidebar-chat-items .item-group {
    margin-bottom: 12px;
}
.sidebar-chat-items .item-group-header {
    color: var(--text-placeholder);
    padding: 2px 8px;
    margin: 0;
}

.sidebar-ipad {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%; /* No ocupa pantalla completa */
    max-width: 360px; /* Tamaño máximo */
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    background-color: var(--bg-sidebar);
}

.sidebar-expanded.sidebar-ipad {
    transform: translateX(0);
}

.top-sidebar {
    display: flex; 
    justify-content: space-between; 
    width: 100%; 
    height: 70px;
    padding: 18px; 
    border-bottom: 1px solid var(--border-default);
    background-color:  var(--bg-sidebar);
}

.logo-sidebar {
    display: flex;
    align-items: center;
    cursor: pointer; 
    text-align: left; 
}

.isotipo-logo {
    max-width: 40px; 
    margin-right: 4px; 
}

.title-logo {
    color: var(--text-primary); 
    font-size: 18px; 
    font-weight: 600; 
    margin: 0;
}

.button-container {
    width: 100%;
    padding: 20px 18px;
    border-bottom: 1px solid var(--border-default);
    background-color: var(--bg-sidebar);
}

.sidebar-chat-items .sidebar-button {
    width: 100%;
    padding: 8px 10px;
    border-radius: 8px;
    margin: 5px 0;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sidebar-chat-items .sidebar-button:hover {
    background-color: var(--sidebar-option-bg-hover);
}

.sidebar-items{
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    overflow: hidden;
}

.empty-conversation {
    font-size: 14px;
    padding: 6px 10px;
    color: var(--gray-500);
    line-height: 22px;
    text-align: center;
}

.dropdown-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: var(--text-placeholder);
}

.dropdown-icon.collapsed {
    transform: rotate(-90deg);
}

.dropdown-icon.expanded {
    transform: rotate(0deg);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
.sidebar-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 100%;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.sidebar-expanded.sidebar-modal {
    transform: translateX(0);
}

.sidebar-collapsed {
    display: none;
}

@media (max-width: 1024px) and (min-width: 769px) {
    .modal-overlay {
        width: 50%; /* Solo cubre la parte del modal */
    }
}