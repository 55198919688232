.config-group {
    margin-bottom: 20px;
}
.config-group h3 {
    font-size: 16px;
    margin-bottom: 10px;
}
.field {
    margin-bottom: 15px;
}
.field span{
    color: var(--text-secondary);
}
.field label {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}  
.subsection-config .title-subsection{
    background-color: var(--bg-subcategory);
    border-radius: 8px;
    margin-bottom: 16px;
    width: 100%;
    padding: 8px 14px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.subsection-config .title-subsection h3{
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
}
.config-section-content{
    margin: 0 14px 8px 14px;
}
.field.first{
    padding-top: 8px;
    border-top: none;
}
.label-actions .delete-button{
    padding: 10px 16px;
    background-color: var(--red-color);
    border-radius: 6px;
    color: var(--base-white);
    font-size: 14px;
}
.label-actions .delete-button:hover{
    background-color: var(--dark-red-color);
    color: var(--base-white);
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--switch-bg-off);
    transition: 0.4s;
    border-radius: 20px;
}

input:checked + .slider {
    background-color: var(--switch-bg-on);
}

.slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: var(--switch-ball);
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider:before {
    transform: translateX(16px);
}
  
@media (max-width: 768px) {
    .field label{
      font-size: 14px;
      max-width: 220px;
    }
    .switch{
        width: 40px;
    }
    .config-section-content{
        margin: 0 12px 4px 12px;
    }
  }