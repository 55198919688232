.custom-select__control {
    width: 220px !important;
    margin: 0 !important;
}

.custom-select__menu {
    width: 220px !important;
    margin: 0 !important;
}

.custom-select__option {
    padding: 10px 12px !important;
    background-color: var(--gray-950);
    color: white; 
    cursor: pointer;
}

.custom-select__option--is-focused {
    background-color: var(--gray-900) !important; 
    border-radius: 6px !important;
}

.custom-select__single-value {
    color: white !important;
}

.custom-select__placeholder{
    font-size: 14px;
}
.expanded-content-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 668px) {
    .expanded-content-item {
        flex: 1 1 100%;
    }
}
  