.header-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.expanded-content th {
    background-color: var(--bg-th-subtable);
    color: var(--base-white);
    font-size: 12px;
    padding: 6px 12px !important;
    text-align: left;
    font-weight: 500;
}
.expanded-content th:first-child{
  border-radius: 7px 0 0 0;
}
.expanded-content th:last-child{
  border-radius: 0 7px 0 0;
}
tbody tr:last-of-type{
  border-radius: 0 0 8px 8px;

}
tbody {
  border-radius: 0 0 8px 8px;
}
tbody tr{
  background-color: var(--bg-internalchat);
  border-radius: 0 0 8px 8px;
}

.expanded-content td:first-child {
  border-radius: 0 0 0 8px;
}
.expanded-content td:last-child {
  border-radius: 0 0 8px 0;
}
.expanded-content td {
    font-size: 14px;
    padding: 10px 12px;
    font-weight: normal;
    text-align: left;
}
  
.number-display {
    flex: 1 1 100px;
    text-align: left;
}
  
.filters-cta-expanded {
    display: flex;
    gap: 12px;
    flex: 1 1 1;
    justify-content: flex-start;
}

th:first-child, td:first-child {
    width: 30%;
}
  
th:last-child, td:last-child {
    width: fit-content; 
}

.toggle {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

table .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -14px;
  right: 0;
  bottom: 0;
  background-color: var(--switch-bg-off);
  transition: 0.4s;
  border-radius: 50px;
  width: 44px;
  height: 24px;
}

table .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50px;
    left: 4px;
    bottom: 4px;
    background-color: var(--switch-ball);
    transition: 0.4s;
}
  
table input:checked + .slider {
    background-color: var(--switch-bg-on);
}

table input:checked + .slider:before {
    transform: translateX(20px);
}

.chip {
    display: inline-block;
    background-color: var(--gray-300);
    color: var(--bg-dark-expandedtable);
    padding: 3px 10px;
    border-radius: 4px;
    margin-right: 5px;
    font-size: 12px;
}

.expanded-content .actions {
    display: flex;
    gap: 5px;
    margin-top: 4px;
    padding: 15px;
    width: fit-content;
}

.edit-button,
.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--icon-reaction-text);
    font-size: 20px;
}

.edit-button:hover {
    color: var(--icon-reaction-text-hover);
}

.delete-button:hover {
    color: var(--red-color);
}
  
.actions .filter-icon {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

  @media (max-width: 668px) {
    .filters-cta-expanded {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .filters-cta-expanded .filter-icon {
      display: inline-block;
      width: 48px;
      height: 48px;
      background-color: var(--border-table);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  
    .filters-cta-expanded select,
    .filters-cta-expanded .search-bar {
      display: none;
    }
  }
  
  .title-expanded {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-primary);
    line-height: 32px;
  }
  
  .total--expanded {
    font-size: 12px;
    font-weight: 300;
    color: var(--text-secondary);
    line-height: 18px;
  }
  
  .expanded-row-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--border-table);
  }
  
  .expanded-row-table th,
  .expanded-row-table td {
    padding: 12px;
    text-align: left;
    color: var(--gray-50);
  }
  
  .expanded-row-table thead tr {
    border-bottom: 1px solid var(--border-table);
    color: var(--gray-100);
    font-size: 14px;
    font-weight: 400;
  }
  
  .expanded-row-table tbody tr {
    border-top: 1px solid var(--border-table);
  }
  
  .expanded-row-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }
  
  .number {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-primary);
    line-height: 24px;
  }
  
  .description {
    font-size: 14px;
    color: var(--text-secondary);
  }
  
  