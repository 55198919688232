.toggle-sidebar-button{
    width: 32px;
    height: 32px;
    border-radius: 8px;
    color: var(--icon-reaction-text);
    display: flex;
    justify-content: center;
    align-items: center;
}
.toggle-sidebar-button:hover{
    background-color: var(--icon-reaction-bg-hover);
    color: var(--icon-reaction-text-hover);
}

.toggle-sidebar-button svg{
    width: 20px;
    height: 20px;
}