.filter-button {
    background: transparent;
    border: none; 
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: transform 0.2s;
    color: var(--gray-500);
}
  
.filter-button-icon {
    font-size: 20px;
    transition: transform 0.2s;
    transform: rotate(90deg);
}

.filter-button:hover{
    color: white;
}