.cite-documents-button{
    display: flex;
    align-items: center;
    position: relative;
    padding: 4px;
    border-radius: 4px;
    color: var(--icon-reaction-text);
    width: 34px;
    height: 34px;
}
.cite-documents-button:hover{
    color: var(--icon-reaction-text-hover);
    background-color: var(--icon-reaction-bg-hover);
}

.cite-documents-icon {
    cursor: pointer;
    font-size: 22px;
    transition: color 0.3s ease;
}

.cite-documents-icon.cited{
    color: var(--cite-documents-cited-color); /* Por ejemplo, un color rojo para representar desagrado */
}
