.copy-message-button{
    display: flex;
    align-items: center;
    position: relative;
    padding: 4px;
    border-radius: 4px;
    color: var(--icon-reaction-text);
    width: 34px;
    height: 34px;
}

.copy-message-button:hover{
    color: var(--icon-reaction-text-hover);
    background-color: var(--icon-reaction-bg-hover);
}

.copy-message-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    transition: color 0.3s ease;
}

.copy-message-icon.copied{
    color: var(--copy-message-copied-color); /* Por ejemplo, un color rojo para representar desagrado */
}