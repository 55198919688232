.human-message-box {
  padding: 12px 16px;
  border-radius: 12px 12px 4px 12px;
  background-color: var(--answer-bg);
  word-wrap: break-word;
  text-align: left;
  margin: 30px 0 10px 0;
  display: flex;
}

.human-message-box p {
  margin: 0;
  font-size: 16px;
  color: var(--answer-text);
}


.message-header {
  margin-right: 10px;
}


.message-tag {
  display: inline-block;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: var(--gray-100);
  text-align: center;
  min-width: 120px;
}

.message-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message-text {
  flex-grow: 1;
  margin: 0;
}

.toggle-metadata-btn {
  border: none;
  background-color: transparent;
  font-size: 12px;
  cursor: pointer;
  padding: 2px 5px;
  color: gray;
}

.toggle-metadata-btn:hover {
  color: black;
}

.metadata-hidden {
  padding: 5px;
  margin-top: -15px;
  border-radius: 5px;
  border: 1px solid #ddd;
}



@media (max-width: 768px) {
  .human-message-box{
    margin-top: 20px;
  }
  .human-message-box p{
    font-size: 14px; 
  }
}