.modal-deleteaccount{
    max-width: 600px;
    background-color: var(--bg-modal);
    border-radius: 16px;
    padding: 0;
    border: 1px solid var(--border-default);
}

@media (max-width: 768px) {
    .modal-deleteaccount {
        width: 100%;
        max-width: unset; 
        border-radius: 12px; 
        margin: 0;
    }
    .modal-header h2{
        line-height: 25px;
    }
    .modal-body {
        overflow-y: auto; 
        flex: 1; 
        font-size: 14px;
    }
}