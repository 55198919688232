.action-hook-button{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: var(--icon-action-text);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--icon-action-border);
}
.action-hook-button:hover{
    background-color: var(--icon-action-bg-hover);
    fill: var(--icon-action-text-hover);
}
.action-hook-button:active{
    background-color: var(--icon-action-bg-hover);
    border: var(--icon-action-border-selected);
}

.action-hook-button.loading {
    cursor: not-allowed;
    pointer-events: none; 
    opacity: 0.4;
}

.hook-button-icon{
    width: 20px;
    height: 20px;
    fill: var(--icon-action-text);
}

.hook-button-icon:hover{
    fill: var(--icon-action-text-hover);
}

@media (max-width: 768px) {
    .action-hook-button{
        width: 36px;
        height: 36px;
    }
}