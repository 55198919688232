.message-text-box {
    border-radius: 5px; 
    color: var(--text-primary);
}

.message-text-box li{
    margin-left: 20px;
    padding-left: 10px;
}

code {
    color: white;             /* Slightly bolded or distinct color for code */
    font-family: 'Courier New', Courier, monospace;  /* Monospace font */
    padding: 2px 5px;           /* Padding around the code */
    border-radius: 3px;         /* Rounded corners */
    font-size: 0.95em;          /* Slightly smaller font size */
}

.copy-code-button {
    background-color: var(--dark-gray-color);
    color: var(--icon-reaction-text);
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.85em;
    margin-bottom: 5px;
}
  
.copy-code-button:hover {
    background-color: var(--icon-reaction-bg-hover);
    color: var(--icon-reaction-text-hover);
}

.code-block-container {
    position: relative;
    margin-bottom: 1em;
    border: 2px solid var(--dark-gray-color);
    border-radius: 5px;
    overflow: hidden;
    background-color: var(--dropdown-option-text);
    color: var(--icon-reaction-text-hover);
    padding-left: 10px;
    padding-bottom: 20px;
  }

pre {
    padding: 5px;
    border-radius: 5px;
    overflow-x: auto;
    white-space: pre-wrap;
    font-size: 0.9em;
}
  
 
.code-header {
display: flex;
justify-content: flex-end; /* Aligns button to the right */
padding: 8px;
background-color: var(--dark-gray-color);
border-bottom: 1px solid var(--dark-gray-color);
}

.message-text-box table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5em;
    text-align: left;
    font-size: 14px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .message-text-box th,
  .message-text-box td {
    padding: 12px 15px;
    border: 1px solid var(--dark-gray-color); /* Light border for table cells */
  }
  
  .message-text-box th {
    background-color: var(--dark-gray-color);
    font-weight: bold;
    text-align: center;
    color: var(--white-color);
  }
  
  
  .message-text-box tr:hover {
    background-color: var(--dark-gray-color); /* Row highlight on hover */
    opacity: 0.5;
  }
  
  .message-text-box td {
    vertical-align: top;
  }
  
  .message-text-box caption {
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    margin: 0.5em 0;
  }
  
  .message-text-box th:first-child {
    border-top-left-radius: 5px;
  }
  
  .message-text-box th:last-child {
    border-top-right-radius: 5px;
  }
