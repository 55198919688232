.account-area {
  height: 100%;
  flex-grow: 1;
  background-color: var(--bg-surface);
  margin: 0;
  padding: 5% 6%;
}

@media (max-width: 768px) {
  .account-area{
    padding: 3% 4% 0 4%;
  }
}