.new-bookmark-button{
    display: flex;
    align-items: center;
    position: relative;
    padding: 4px;
    border-radius: 4px;
    color: var(--icon-reaction-text);
    width: 32px;
    height: 32px;
}
.new-bookmark-button:hover{
    color: var(--icon-reaction-text-hover);
    background-color: var(--icon-reaction-bg-hover);
}

.new-bookmark-icon {
    cursor: pointer;
    width: 25px;
}
.marked {
    /* Cambia el color o estilo que desees cuando el usuario no le guste la respuesta */
    fill: var(--bookmark-marked-color); /* Por ejemplo, un color rojo para representar desagrado */
  }