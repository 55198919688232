.more-button {
    display: flex;
    align-items: center;
    position: relative;
    padding: 6px;
    border-radius: 4px;
    color: var(--icon-reaction-text);
    width: 32px;
    height: 32px;
}

.more-options-button {
    position: relative;
}

.more-button:hover {
    color: var(--icon-reaction-text-hover);
    background-color: var(--icon-reaction-bg-hover);
}

.more-options-button-icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    transition: color 0.3s ease;
}

.options-menu {
    position: absolute;
    padding: 8px;
    top: 100%;
    left: 0;
    background-color: var(--dropdown-bg);
    border: 1px solid var(--dropdown-border);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
    z-index: 1000;
    width: 200px;
    border-radius: 12px;
    margin-top: 5px; 
}

.option {
    display: flex;
    align-items: center;
    color: var(--dropdown-option-text);
    padding: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
    border-radius: 6px;
}

.option:hover {
    background-color:  var(--dropdown-option-bg-hover);
    color: var(--dropdown-option-text-hover);
}

.icon-option {
    width: 36px;
    height: 36px;
    padding: 4px;
}

.options-menu .icon-option {
    width: 30px;
    height: 30px;
    padding: 4px;
}